.main_conatiner {
  padding: 30px;
  margin: 20px;
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
}

.container_1 {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 10px 10px 10px rgb(184, 183, 183);
}

.container_2 {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 5px;
  margin-bottom: 10px;
  flex: 0 0 100%;
}
.container_2 > div {
  margin-right: 20px;
}
.container_1 > div {
  margin-right: 40px;
}

.container_3 {
  background-color: white;
  padding: 4px;
  flex: 0 0 100%;
}

.discription_container {
  background-color: white;
  max-width: 900px;
  padding: 5px;
  font-size: 14px;
  line-height: 1.2;
}

.center_div{
  margin: 0 auto;
  width:80%
}

.btn.btn-square {
  border-radius: 0;
}

.searchbar {
  top: 56px;
  position: sticky;
  background: white;
  z-index: 1
  
}

.astext {
  background:none;
  border:none;
  margin:0;
  padding:0;
  cursor: pointer;
  color: #6c757d!important;
}

.tooltip {
  border-bottom: 1px dashed #999;
  text-decoration: none; 
}