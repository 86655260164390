.search_bar {
    border: none;
  }

.search_button {
  background-color: white;
  border: none;
  padding: 7px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 0px 3px 3px 0px;
}